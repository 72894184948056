@import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

.navbar navbar-expand-lg navbar-light {
  padding: 0;
}

.fullHeightRow {
  height: 100%;
  margin: 0;
  /* To remove any default margin */
}

html,
body {
  height: 100%;
  margin: 0;
}

/* Ensure the #root div takes up the full height of its parent */
#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Ensure the child div of #root takes up the full height of its parent */
#root>div {
  height: 100%;
}

.faqLink {
  text-decoration: none;
}

.dataRequestLink {
  text-decoration: none;
}

/* Disable highlight on click */
.accordion-button:focus,
.accordion-button:hover,
.accordion-button:not(.collapsed) {
  background-color: inherit !important;
  box-shadow: none !important;
  color: inherit !important;
}

/* If there's a border change on focus/hover, reset it */
.accordion-button:focus,
.accordion-button:hover {
  border-color: inherit !important;
}

.headerLogo {
  vertical-align: bottom;
  height: 50%;
  /* width: 50%; */
  /* This will ensure the image is bottom-aligned */
}

.brand-text {
  font-size: 1.75em;
  /* Adjusts the size of the text. Change 1.5em as needed. */
  letter-spacing: 0.05em;
  /* Adjusts the space between characters. Change 0.05em as needed. */
  word-spacing: 0.1em;
  /* Adjusts the space between words. Change 0.1em as needed. */

  color: white;
  /* Adjust as needed to make text bigger */
  font-weight: bold;
  /* Makes text bolder */
  vertical-align: bottom;
  /* Aligns text to the bottom */
}

.headerLogo {
  /* width: 48px; */
  vertical-align: bottom;
  height: 58px;
}

.chatLogo {
  width: 104px;
  height: 104px;
}

.mapboxgl-popup-content {
  width: auto;
  height: auto;
  max-height: none;
  max-width: 500px;
  border-radius: 10px !important;
  padding: 6px;
  /* this will be your border size */
  box-shadow: inset 0 0 0 1000px white, 2px 2px 15px rgba(0, 0, 0, 0.2);
  /* Added external shadow */
  position: relative;
  /* To position the "x" inside it */
}

.close-popup {
  content: 'x';
  position: absolute;
  top: 4px;
  right: 12px;
  color: #757575;
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-weight: 100;
  font-size: 17px;
  cursor: pointer;
}

thead th,
td {
  font-size: 12px;
  padding: 6 px;
  border: none;
  word-wrap: break-word;
  /* Added this to ensure content wraps */
  border-bottom: 1px solid #e0e0e0;
  /* Faint line at the bottom of each cell */
}

/* This will add a faint vertical line to the right of every cell except the last one in a row */
td:not(:last-child),
thead th:not(:last-child) {
  border-right: 10px solid #ffffff;
}

/* LEGEND CSS */
.legend {
  position: absolute;
  top: 109px;
  right: 8px;
  z-index: 1;
  cursor: move;
  /* Added to indicate draggable */
}

.legend-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  cursor: pointer;
  padding: 0;
  border: none;
  background: none;
}

.legend-border {
  border: 2px solid rgba(128, 128, 128, 0.25);
  border-radius: 5px;
  padding: 0px;
  /* adjust this to control the space between border and background */
}

.legend-background {
  background-color: white;
  border-radius: 4px;
  padding: 2px;
}

.legend-toggle:hover .legend-border {
  background-color: rgba(220, 220, 220, 0.7);
}

.legend-toggle-icon {
  width: 25px !important;
  height: 25px !important;
}

/* Remove display: none; */
.legend-dropdown {
  position: absolute;
  top: 34px;
  right: 0;
  background-color: white;
  border-radius: 5px;
  max-height: 0;
  /* Start collapsed */
  opacity: 0;
  /* Start transparent */
  overflow-y: auto;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  width: 145px;
  /* Set a fixed width for the dropdown */
  transition: opacity 0.3s ease, max-height 0.3s ease;
  /* Fade-in & slide-down duration */
}

/* Adjust to hover and increase the max-height as needed */
.legend:hover .legend-dropdown {
  max-height: 200px;
  /* Adjust this value if your dropdown content is taller */
  opacity: 1;
  /* End opaque */
}

.legend-scrollable {
  padding: 5px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.legend-icon {
  width: 15px !important;
  height: 15px !important;
  margin-right: 5px;
  display: block;
  flex-shrink: 0;
  max-width: 100%;
  max-height: 100%;
}

.legend-table {
  width: 100%;
  border-collapse: collapse;
}

.legend-row {
  border-bottom: 1px solid #eaeaea;
}

.legend-row:last-child {
  border-bottom: none;
}

.legend-cell-icon,
.legend-cell-description {
  padding: 5px;
}

.legend-cell-icon {
  width: 30px;
  /* Adjust as needed */
  text-align: center;
}

/* END Legend CSS */
.satellite-toggle {
  position: absolute;
  top: 72px;
  right: 8px;
  z-index: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
}

.satellite-border {
  border: 2px solid rgba(128, 128, 128, 0.25);
  border-radius: 5px;
  padding: 0px;
  /* adjust this to control the space between border and background */
}

.satellite-background {
  background-color: white;
  border-radius: 4px;
  padding: 5px;
}

.satellite-toggle:hover .satellite-border {
  background-color: rgba(220, 220, 220, 0.7);
}

.satellite-toggle-icon {
  width: 19px !important;
  height: 19px !important;
}

/* Style for when the satellite view is active */
.satellite-toggle.active .satellite-background {
  background-color: rgb(203, 200, 200);
}

.fullHeightRow .col {
  height: 100%;
}

.fullHeightRow {
  height: 97vh;
}

body {
  height: 100%;
  background-image: linear-gradient(to right,
      #121e31,
      #142336,
      #16293b,
      #182e40,
      #1b3445,
      #1b3445,
      #1b3445,
      #1b3445,
      #182e40,
      #16293b,
      #142336,
      #121e31);
}

ul {
  margin: 0;
  padding: 0;
}

a {
  transition: all 350ms ease-in-out;
  font-weight: 400;
  text-decoration: unset;
}

.mapboxgl-popup.mapboxgl-popup-anchor-bottom {
  max-width: 500px !important;
}

div#root {
  height: 100vh;
  overflow-y: hidden;
}

/* Header Css Start */
.navbar-nav a {
  color: #fff;
  margin-left: 50px;
  transition: color 0.3s ease, text-decoration 0.3s ease;
  /* Smooth transition for color and text-decoration properties */
}

.navbar-nav a:hover {
  color: #a8aebb;
  /* Lighter color when hovered */
  text-decoration: underline;
  /* Underline the link when hovered */
}

.header-profile {
  margin: 0px 0px 20px 0px;
}

a.hdr-btn {
  background-color: #fff;
  color: #101828;
  padding: 12px 40px;
  border-radius: 30px;
}

.navbar-expand-lg .navbar-nav {
  align-items: center;
}

#basic-navbar-nav {
  justify-content: end;
  margin-right: 50px;
}

/* Welcome Page Css Start */
.card-bg {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  height: 100vh;
  overflow: auto;
  /* Allow scrolling if content overflows */
  display: flex;
  flex-direction: column;
}

.home-card-bg {
  background-color: #fff;
  border-radius: 10px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chatbox {
  margin-top: 3%;
  /* margin-bottom: 3%; */
  margin-left: 3%;
  margin-right: -1%;
  text-align: center;
  background-color: #f2f4f7;
  border-radius: 10px;
  /* padding: 30px 20px; */
  height: 87vh;
  /* Adjust to your preference */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding-bottom: 15px;
  flex-grow: 1;
}

.chat-opt {
  margin: 10px 0px 10px 0px;
}

.chat-scroll {
  overflow: scroll;
  height: 80vh;
  /* margin-top: 20px; */
  /* box-shadow: 0 0 10px #dee0e5; */
}

.map-icon {
  height: 30px;
  width: 30px;
}

.marker-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.marker-btn img {
  width: 20px;
  height: 20px;
}

.popup-container {
  padding: 0px 0px 0px 0px;
  border-radius: 10px;
}

.popup-row {
  display: flex;
  background-color: #fff;
  flex-direction: row;
}

.popup-row p {
  margin: 0px 0px 0px;
}

.api-margin {
  margin-left: 60px;
}

.operator-margin {
  margin-left: 30px;
}

.depth-margin {
  margin-left: 48px;
}

.phone-margin {
  margin-left: 47px;
}

.classification-margin {
  margin-left: 10px;
}

.lease-margin {
  margin-left: 18px;
}

.chattype form {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  /* this will keep the button at the bottom */
}

form.chatform {
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 10px;
}

form.chatform div {
  width: 100%;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.chattype {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.chattype button {
  border-radius: 100%;
  width: 60px;
  height: 55px;
  border: none;
  margin-left: 10px;
  margin-bottom: 4px;
  /* adjust this value for desired bottom spacing */
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
  /* Smooth transition for box-shadow and background-color */
}

.chattype button:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  /* Adds a subtle shadow when hovered */
  background-color: #eee;
  /* Light gray background when hovered, adjust color if needed */
}

.chattype input {
  border: 1px solid #d0d5dd;
  padding: 16px 24px;
  border-radius: 30px;
}

.reset-button {
  height: 40px;
  width: 100px;
  border-radius: 20px;
  background-color: gray;
  justify-content: center;
  align-items: center;
  align-self: center;
  cursor: pointer !important;
  /* Changes the cursor to a hand icon when hovered over the button */
  transition: background-color 0.3s !important;
  /* This is optional. It adds a smooth transition effect when the color changes */
}

.reset-button:hover {
  background-color: #444 !important;
  cursor: pointer !important;
}

.reset-button p {
  color: #fff;
  font-size: 18px;
}

.table-box {
  margin-top: 2%;
  margin-bottom: 3%;
  /* margin-left: 3%; */
  margin-right: 3%;
  background-color: #f2f4f7;
  padding: 20px 20px 10px 20px;
  /* Reduced padding-bottom */
  border-radius: 10px;
  height: 87vh;
  /* Adjust to your preference */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.table-box>div {
  flex-grow: 1;
  /* Adjust to your preference */
}

.map-blk img {
  width: 100%;
  height: 80vh;
  object-fit: cover;
  flex-grow: 1;
}

.map-blk {
  height: 60vh;
  /* Adjust to your preference */
  /* or whatever height you want */
  width: 100%;
  flex-grow: 1;
}

.map-blk {
  border-radius: 10px;
  overflow: hidden;
}

.table-bg {
  background-color: #f2f4f7;
  padding: 20px;
  margin-top: 20px;
  border-radius: 20px;
}

.map-table {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 18px;
  overflow: scroll;
  height: 25.5vh;
  /* Adjust to your preference */
  margin-top: 12px;
  box-shadow: 0 0 10px #dee0e5;
  flex-grow: 1;
}

.map-table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.map-table::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
  height: 12px;
}

.map-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #9ea3ad;
}

.map-table th {
  min-width: 150px;
  border-bottom: 1px solid #6670858f;
  color: #667085;
  padding: 0 0 13px;
}

.map-table td {
  border-bottom: 1px solid #6670858f;
  color: #667085;
  padding: 0 0 13px;
}

.map-table td {
  border-bottom: 1px solid #6670858f;
  color: #667085;
  padding: 8px 0;
}

.status {
  text-align: center;
  width: 90%;
  display: inline-block;
  padding: 7px 0;
  border-radius: 30px;
  position: relative;
}

.status::before {
  content: "";
  height: 8px;
  width: 8px;
  position: absolute;
  background-color: #000;
  left: 10px;
  top: 17px;
  border-radius: 30px;
}

.st-close::before {
  background-color: #cb121d;
}

.st-open::before {
  background-color: #027a48;
}

.st-close {
  background-color: #cb121d2e;
  color: #cb121d;
}

.st-open {
  background-color: #027a483d;
  color: #027a48;
}

.chatbox ul {
  text-align: left;
  display: inline-block;
}

.chatbox ul li a {
  color: #000;
  font-weight: 600;
}

.chatbox li {
  list-style: none;
  margin: 16px 0;
}

.chatbox li img {
  margin-right: 10px;
  width: 30px;
}

.chat-profile-header {
  display: flex;
  height: 20px;
  flex-direction: row;
  border-radius: 10px;
  background-color: #4b5764;
  padding: 10px 20px 50px 0px;
  justify-content: space-evenly;
}

.chat-profile-header img {
  height: 40px;
  width: 40px;
}

.chat-profile-header p {
  color: #fff;
  font-size: 14px;
  text-align: center;
}

.fire-cicle {
  height: 40px;
  width: 140px;
  border-radius: 40px;
  align-items: center;
  justify-content: center;
  background-color: #0083e9;
}

.fire-cicle img {
  height: 20px;
  width: 20px;
  margin: 10px 0;
}

.chat-box {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  background-color: #fff;
  padding: 10px 20px 0px 20px;
  justify-content: space-evenly;
}

.chat-message p {
  width: 90%;
  text-align: left;
  margin: 0px 10px;
}

.messageArea {
  height: 80vh;
  overflow-y: auto;
}

.fixed-height {
  height: 200px;
}

.chat-box p {
  font-size: 14px;
}

.chat-message-footer {
  display: flex;
  flex-direction: row;
}

.chat-message-footer-circle {
  /* gap: 12px; */
  height: 36px;
  width: 36px;
  border-width: 1px;
  /* border-radius: 100px; */
  /* border-color: #D0D5DD; */
  /* padding: 8px 8px 8px 8px; */
}

.chat-message-footer-circle img {
  height: 20px;
  width: 20px;
}

.download-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.file-blk span {
  background-color: #197aeb;
  padding: 8px;
  border-radius: 100%;
  margin-right: 8px;
  display: inline-block;
}

.file-blk span img {
  width: 20px;
}

.clr-btn {
  background-image: linear-gradient(to right,
      #1679ee,
      #0083e9,
      #008be2,
      #0092d8,
      #0098cd,
      #009fca,
      #00a6c5,
      #00acbe,
      #00b6b9,
      #00bfac,
      #00c798,
      #3acd7e);
  color: #fff !important;
  transition: transform 0.3s, opacity 0.3s !important;
  /* Smooth transition for transform and opacity */
}

.clr-btn img {
  height: 18px;
  width: 18px;
  margin-left: 5px;
  margin-bottom: 3px;
}

.reset-file {
  display: flex;
  /* justify-content: space-between;
  align-items: center; */
  margin-top: 20px;
  margin-bottom: 10px;
}

.reset-blk button {
  border: none;
  color: #fff;
  padding: 12px 40px;
  border-radius: 30px;
}

.file-blk h6 {
  /* margin: 0; */
  font-size: 14px;
}

.reset-blk button img {
  margin-right: 8px;
}

.clr-btn-reset {
  background-image: linear-gradient(to right,
      #1679ee,
      #0083e9,
      #008be2,
      #0092d8,
      #0098cd,
      #009fca,
      #00a6c5,
      #00acbe,
      #00b6b9,
      #00bfac,
      #00c798,
      #3acd7e);
  color: #fff !important;
  transition: transform 0.3s !important;
  /* Smooth transition for transform and opacity */
  margin-top: 100vh;

}

.clr-btn-reset:hover {
  transform: scale(1.02);
  /* this will slightly enlarge the button when hovered */
}

.button-link {
  display: inline-block;
  text-decoration: none;
  padding: 5px 15px;
  border-radius: 4px;
}

.clr-btn-alert {
  background-image: linear-gradient(to right,
      #ee1717,
      /* starting color */
      #e90000,
      #e20000,
      #db0000,
      #d50000,
      /* ending color */
      #cf0000,
      #c80000,
      #c20000,
      #bd0000,
      #b70000,
      #b10000,
      #ac0000);
  color: #fff !important;
  display: inline-block;
  text-decoration: none;
  padding: 5px 15px;
  border-radius: 20px;
  margin-top: 10px;
}

.prod-btn {
  background-image: linear-gradient(to right,
      #1679ee,
      #0083e9,
      #008be2,
      #0092d8,
      #0098cd,
      #009fca,
      #00a6c5,
      #00acbe,
      #00b6b9,
      #00bfac,
      #00c798,
      #3acd7e);
  color: #fff !important;
  display: inline-block;
  text-decoration: none;
  padding: 5px 15px;
  border-radius: 20px;
  margin-top: 10px;
}

.download-blk button {
  border: none;
  color: #fff;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 14px;
  /* Adjust font size */
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
}

.download-blk button:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  /* Scales the button up slightly on hover */
  background-color: #1568cc;
  /* You can change this to a darker shade of the original color for hover effect */
}

.file-blk h6 {
  margin: 0;
}

.download-blk button img {
  margin-right: 8px;
}

/* Login Page Css Start */
.card-blue {
  background-color: #f2f4f7;
}

.about-sec {
  overflow: scroll;
}

.about-sec-login {
  overflow: scroll;
}

.formblock {
  width: 750px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 20px;
  padding: 50px 40px 40px;
}

.formblock form {
  margin-top: 30px;
}

.formblock .form-control,
.formblock select {
  border: 1px solid #d0d5dd;
  border-radius: 30px;
  padding: 13px 20px;
}

.text-right {
  text-align: right;
}

.formblock a {
  color: #146dd5;
}

.formblock a svg {
  margin-left: 12px;
}

.formblock button {
  width: 100%;
  border: none;
  padding: 15px 0;
  border-radius: 30px;
  margin: 22px 0;
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
  /* Added transition for smooth effect */
}

.formblock button:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  /* Scales the button up slightly on hover */
  background-color: rgba(0, 0, 0, 0.1);
  /* Adds a slight shade to the button's background on hover. Adjust the values if needed. */
}

.formblock h6 {
  text-align: center;
  color: #667085;
}

.formblock h6 a {
  margin-left: 8px;
}

.formblock span {
  display: inline-block;
  text-align: center;
  width: 100%;
  position: relative;
  color: #d0d5dd;
  font-size: 22px;
}

.formblock span::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  background-color: #d0d5dd;
  top: 17px;
}

.formblock span b {
  font-weight: 400;
  background-color: #fff;
  position: relative;
  padding: 0 20px;
}

.google-login a {
  width: 100%;
  border: 2px solid #18ca8e;
  padding: 15px 0;
  border-radius: 30px;
  margin: 22px 0;
  display: inline-block;
  text-align: center;
  background: linear-gradient(to right, #33cc81, #0b7eec, #20ca8a, #28cb86);
  background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.vh {
  height: 100vh;
  overflow: auto;
  /* Allow scrolling if content overflows */
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 40px;
  /* Adjust this value for more or less padding */
  display: flex;
  flex-direction: column;
}

/* Data Request Page Css Start */
.nav-tabs button {
  font-size: 18px;
  color: #667085;
  border: none !important;
  border-bottom: 1px solid #d0d5dd !important;
  border-radius: 0;
  margin: 0;
  position: relative;
}

.formblock .nav-tabs button.active::before {
  content: "";
  height: 4px;
  width: 100%;
  position: absolute;
  background-image: linear-gradient(to right,
      #1679ee,
      #0083e9,
      #008be2,
      #0092d8,
      #0098cd,
      #009fca,
      #00a6c5,
      #00acbe,
      #00b6b9,
      #00bfac,
      #00c798,
      #3acd7e);
  bottom: -1px;
  left: 0;
}

.formblock .nav-tabs {
  text-align: center;
  display: block;
}

.formblock .nav-tabs li {
  display: inline-block;
  width: 50%;
}

.acc-blk .accordion-item {
  border: none;
  border-bottom: 1px solid #00000021;
}

.acc-blk .accordion-button {
  color: #000;
  border: none !important;
  background-color: transparent;
  margin: 10px 0;
  font-weight: 600;
  border-radius: 0;
}

.acc-blk .accordion-body {
  padding: 20px 0;
}

/* Chat Bot Css Start */
.people-chat {
  background-color: #4b5764;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 8px 0;
  margin-left: 80px;
  margin-right: 10px;
}

.people-chat p {
  color: #fff;
  text-align: left;
  margin: 0;
  padding-left: 10px;
  font-size: 14px;
}

.people-chat img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  object-fit: cover;
}

.robot-chat {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 16px 20px;
  border-radius: 10px;
  margin: 20px 0;
  margin-left: 10px;
  margin-right: 80px;
}

.robot-chat img {
  width: 40px;
  height: 40px;
}

.robot-txt {
  padding-left: 18px;
}

.robot-txt p {
  text-align: left;
  margin: 0;
  font-size: 15px;
}

@keyframes ellipsis {

  0%,
  100% {
    content: ".";
  }

  33% {
    content: "..";
  }

  66% {
    content: "...";
  }
}

.loading-ellipsis::after {
  content: ".";
  animation: ellipsis 1s infinite;
}

.chat-icons {
  display: flex;
  justify-content: space-between;
}

.chat-icons ul {
  display: inline-flex;
}

.chat-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.chat-icons li img {
  width: 20px !important;
  height: auto !important;
  margin: 0 !important;
  cursor: pointer;
}

.chat-icons img {
  width: 36px;
  height: 36px;
}

.chat-icons li {
  border: 2px solid #d0d5dd;
  border-radius: 100%;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  cursor: pointer;
}

a#basic-nav-dropdown {
  /* background-image: url(images/avatar.png); */
  background-repeat: no-repeat;
  /* padding-left: 48px; */
  color: #fff;
  /* margin-left: 20px; */
  background-size: 21%;
}

.profile-circle-header {
  height: 60px;
  width: 60px;
  border-radius: 60px;
  background-color: gray;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: flex;
}

.profile-circle-header p {
  font-weight: 600;
  font-size: 25px !important;
  margin: 0 !important;
  color: #fff;
  padding: 1px !important;
}

.dropdown-menu a img {
  margin-right: 8px;
}

.dropdown-menu {
  width: 100%;
  top: 123% !important;
}

/* Price Page Css Start */
.pric-blk {
  width: 900px;
  margin: 0 auto;
}

.pric-blk .nav-tabs {
  justify-content: center;
  border: none;
}

.price-box {
  padding: 30px;
  border-radius: 20px;
  position: relative;
  margin-top: 20px;
}

.w-box {
  border-color: #d0d5dd;
  background-color: #fff;
}

.b-box {
  background-image: linear-gradient(to right,
      #121e31,
      #142336,
      #16293b,
      #182e40,
      #1b3445,
      #1b3445,
      #1b3445,
      #1b3445,
      #182e40,
      #16293b,
      #142336,
      #121e31);
}

.price-box h3,
.price-box h4 {
  text-align: center;
  color: #000;
  font-weight: 800;
}

.price-box h3 {
  border-bottom: 1px solid #dddddda6;
  padding-bottom: 20px;
}

.price-box h3 span {
  color: #475467;
  font-size: 16px;
  font-weight: 400;
}

.price-box h3 {
  font-size: 44px;
}

.price-box ul {
  list-style: none;
  background-color: #ffffff40;
  border-radius: 10px;
  padding: 20px;
  min-height: 450px;
}

.price-box ul li {
  margin: 12px 0;
  position: relative;
}

.price-box h4 {
  margin: 18px 0;
}

.b-box,
.b-box h3,
.b-box h4 {
  color: #fff;
}

.price-box button {
  width: 100%;
  margin-top: 15px;
  border: 1px solid #d0d5dd;
  padding: 12px 0;
  border-radius: 10px;
}

.pric-blk h2 {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  margin: 0 0 30px;
}

.pric-blk li.nav-item button {
  background-color: transparent !important;
  color: #344054;
  font-weight: 600;
  border: none !important;
}

.b-box h3 span {
  color: #fff;
}

.price-box small {
  position: absolute;
  border: 2px solid #d7d7d7;
  right: 20px;
  top: -18px;
  background-color: #fff;
  color: #000;
  border-radius: 30px;
  padding: 5px 20px;
}

.switch label {
  position: relative;
  width: 60px;
  height: 34px;
  display: inline-block;
  background: #146dd557 !important;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.switch label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  width: 25px;
  height: 25px;
  background: #146dd5;
  border-radius: 50%;
  box-shadow: 1px 3px 6px #666666;
}

.switch input:checked+label {
  background: #009900;
}

.pric-blk .nav-tabs li {
  margin: 0 30px;
}

.switch input:checked+label:after {
  left: auto;
  right: 2px;
}

.switch input {
  display: none;
}

.pric-blk .tab-content>.tab-pane {
  display: block;
}

.tab-flx {
  display: flex;
  align-items: center;
  justify-content: center;
}

.switch {
  margin: 0 15px;
}

.tab-flx h5 {
  color: #344054;
  font-weight: 700;
}

.tab-flx h5 span {
  color: #667085;
  margin-left: 3px;
  font-weight: 600;
}

/* Profile Modal Css Start */
.profile-modal .modal-dialog {
  margin-right: 28px;
}

.prof-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.prof-img {
  display: flex;
  align-items: flex-start;
}

.prof-img img {
  margin-right: 10px;
}

.profile-circle {
  height: 70px;
  width: 70px;
  border-radius: 70px;
  background-color: gray;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: flex;
}

.profile-circle p {
  font-weight: 600;
  font-size: 30px !important;
  margin: 0 !important;
  color: #667085;
  padding: 1px !important;
}

.prof-email h5 {
  margin: 0 0 4px;
  color: #101828;
}

.prof-email p {
  margin: 0 !important;
  color: #667085;
  padding: 0 !important;
}

.prof-detail button {
  border: 2px solid #18ca8e;
  padding: 11px 30px;
  border-radius: 30px;
  /* display: inline-block; */
  text-align: center;
  background: linear-gradient(to right, #33cc81, #0b7eec, #20ca8a, #28cb86);
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.profile-main form input {
  border: 1px solid #d0d5dd;
  border-radius: 30px;
  padding: 13px 20px;
}

.chattype textarea.auto-expand {
  border: 1px solid #d0d5dd;
  padding: 13px 20px 10px 20px;
  /* Adjusted padding-bottom */
  border-radius: 30px;
  resize: none;
  overflow-y: auto;
  /* This will automatically show scroll when content overflows */
  height: 52px;
  max-height: 150px;
}

.prof-detail {
  margin: 0 0 30px;
}

.close-btn .btn-close {
  border: 1px solid #d0d5dd;
  border-radius: 100%;
}

.close-btn .modal-header h3 {
  margin: 0;
  color: #101828;
  font-weight: 700;
  font-size: 40px;
}

.clr-icon {
  font-size: 22px;
  background: linear-gradient(to right, #33cc81, #0b7eec, #20ca8a, #28cb86);
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

.profile-main p {
  font-size: 18px;
  text-align: center;
  padding: 0 30px;
  margin: 0;
  background: linear-gradient(to right, #33cc81, #0b7eec, #20ca8a, #28cb86);
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

.close-btn .modal-content {
  border-radius: 15px;
  overflow: hidden;
  padding: 10px 14px;
}

.close-btn .modal-header {
  border-bottom: none;
}

.uploadfile {
  border: 2px solid #d0d5dd;
  position: relative;
  text-align: center;
  padding: 10px 0;
  border-radius: 30px;
  color: #000;
  cursor: pointer;
}

.uploadfile svg {
  margin-right: 7px;
}

.uploadfile input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  opacity: 0;
  font-size: 0;
  width: 100%;
  cursor: pointer;
}

.prof-email button {
  border: none;
  color: #cb121d !important;
  background-image: none;
  -webkit-text-fill-color: unset;
}

/* Online System Page */

.online-card-bg {
  background-color: #f9fafb;
  border-radius: 10px 10px 0px 0px;
  padding: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cross-icon {
  height: 36px;
  width: 36px;
  border-radius: 100px;
  border: 1px solid #d0d5dd;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.dropwDown-test {
  border-radius: 100px;
  margin-right: 50px;
}

.cross-icon:hover {
  background-color: darkgray;
}

.cross-icon img {
  height: 10px;
  margin-left: 12px;
  margin-top: 8px;
}

.welcome-sec {
  overflow: scroll;
}

.fullHeightRow-online-card {
  /* height: 100%; */
  width: 100%;
  background-color: #fff;
  border-radius: 0px 0px 10px 10px;
  padding: 25px;
  /* To remove any default margin */
}

.fullHeightRow-online-card .col {
  /* height: 100%; */
}

.fullHeightRow-online-card {
  /* height: 90vh; */
}

.query-card {
  margin: 20px 0px 0px 0px;
  padding: 0px 0px 0px 20px;
  border-radius: 16px;
  border: 1px solid #0000001a;
  gap: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dropdown-month {
  border-radius: 30px;
  height: 48px;
  width: 140px;
  border: 1px solid #0000001a;
  justify-content: center;
}

.dropdown-year {
  border-radius: 30px;
  height: 48px;
  width: 140px;
  border: 1px solid #0000001a;
  justify-content: center;
}

.submit-calendar {
  width: 12%;
  border: 1px solid #18ca8e;
  padding: 10px 0;
  border-radius: 30px;
  margin: 22px 0;
  display: inline-block;
  text-align: center;
  background: linear-gradient(to right, #33cc81, #0b7eec, #20ca8a, #28cb86);
  background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.search-criteria-box {
  background-color: #f2f4f7;
  border-radius: 10px;
  padding: 0px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding-bottom: 100px;
  padding-top: 100px;
  margin-top: 10px;
}

.search-criteria-box p {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: -0.01em;
  text-align: left;
}

.search-row {
  flex-direction: row;
  display: flex;
}

.span-bold {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: -0.01em;
}

.span-default {
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: -0.01em;
  margin-left: 5px;
}

.fullHeightRow-search {
  height: 100%;
  margin: 0;
}

.fullHeightRow-search .col {
  height: 100%;
}


.pagination-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.pagination-left-text {
  margin-top: 10px;
}

.page-size-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.page-size-dropdown {
  border-radius: 100px;
  background-color: #000;
  margin-left: 10px;
}

.pagination-button-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination-left-button {
  border: 1px solid #18ca8e;
  padding: 10px 0;
  border-radius: 10px;
  display: inline-block;
  text-align: center;
  background: linear-gradient(to right, #33cc81, #0b7eec, #20ca8a, #28cb86);
  background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.pagination-left-button img {
  height: 20px;
  height: 20px;
}

.page-button {
  border: 1px solid #18ca8e;
  padding: 10px 0;
  border-radius: 10px;
  display: inline-block;
  text-align: center;
  background: linear-gradient(to right, #33cc81, #0b7eec, #20ca8a, #28cb86);
  background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.pagination-right-button {
  background-image: linear-gradient(to right,
      #1679ee,
      #0083e9,
      #008be2,
      #0092d8,
      #0098cd,
      #009fca,
      #00a6c5,
      #00acbe,
      #00b6b9,
      #00bfac,
      #00c798,
      #3acd7e);
  color: #fff !important;
  padding: 20px 0;
  border-radius: 10px;
  display: inline-block;
  text-align: center;
  /* width: 14%; */
}

.pagination-right-button img {
  height: 20px;
  height: 20px;
}

.pagination {
  margin: 0px 20px 0px 20px;
  justify-content: center;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-image: linear-gradient(to right,
      #1679ee,
      #0083e9,
      #008be2,
      #0092d8,
      #0098cd,
      #009fca,
      #00a6c5,
      #00acbe,
      #00b6b9,
      #00bfac,
      #00c798,
      #3acd7e);
  font-weight: 600;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #000;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #000;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.cross-icon {
  /* display: flex;
  justify-content: center; */
  /* Aligns items horizontally to the center */
  align-items: center;
  /* Aligns items vertically to the center */
  /* height: 100%; */
  /* You might need to set a specific height or use 100% */
}

.dropdown-month {
  text-align: center;
  /* Additional styles if needed */
}

.dropdown-month option {
  text-align: center;
}

.dropdown-year {
  text-align: center;
  /* Additional styles if needed */
}

.dropdown-year option {
  text-align: center;
}

@media only screen and (max-width: 1399px) {}

.online-card-bg {
  background-color: #f9fafb;
  border-radius: 10px 10px 0px 0px;
  padding: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cross-icon {
  height: 36px;
  width: 36px;
  border-radius: 100px;
  border: 1px solid #d0d5dd;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.dropwDown-test {
  border-radius: 100px;
}

.cross-icon:hover {
  background-color: darkgray;
}

.cross-icon img {
  height: 10px;
  width: 10px;
  margin-left: 12px;
  margin-top: 6px;
}

.welcome-sec {
  overflow: scroll;
}

.fullHeightRow-online-card {
  /* height: 100%; */
  width: 100%;
  background-color: #fff;
  border-radius: 0px 0px 10px 10px;
  padding: 25px;
  /* To remove any default margin */
}

.fullHeightRow-online-card .col {
  /* height: 100%; */
}

.fullHeightRow-online-card {
  /* height: 90vh; */
}

.query-card {
  margin: 20px 0px 0px 0px;
  padding: 0px 0px 0px 20px;
  border-radius: 16px;
  border: 1px solid #0000001a;
  gap: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.submit-calendar {
  width: 12%;
  border: 1px solid #18ca8e;
  padding: 10px 0;
  border-radius: 30px;
  margin: 22px 0;
  display: inline-block;
  text-align: center;
  background: linear-gradient(to right, #33cc81, #0b7eec, #20ca8a, #28cb86);
  background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  transition: box-shadow 0.0s ease-in-out;
  /* Remove if using old hover*/
}

/* THIS IS THE HOVER WE USE FOR ALL OF THE OTHER BUTTONS
.submit-calendar:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  background-color: #eee;
}
*/

.submit-calendar:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  /* Shadow on hover */
}

.search-criteria-box {
  background-color: #f2f4f7;
  border-radius: 10px;
  padding: 0px 20px;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 100px;
  padding-top: 100px;
  margin-top: 15px;
}

.search-criteria-box p {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: -0.01em;
  text-align: left;
}

.search-row {
  flex-direction: row;
  display: flex;
}

.span-bold {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: -0.01em;
}

.span-default {
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: -0.01em;
  margin-left: 5px;
}

.fullHeightRow-search {
  height: 100%;
  margin: 0;
}

.fullHeightRow-search .col {
  height: 100%;
}

.pagination-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.pagination-left-text {
  margin-top: 10px;
}

.page-size-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.page-size-dropdown {
  border-radius: 100px;
  background-color: #000;
  margin-left: 10px;
}

.pagination-button-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination-left-button {
  border: 1px solid #18ca8e;
  padding: 10px 0;
  border-radius: 10px;
  display: inline-block;
  text-align: center;
  background: linear-gradient(to right, #33cc81, #0b7eec, #20ca8a, #28cb86);
  background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.pagination-left-button img {
  height: 20px;
  height: 20px;
}

.page-button {
  border: 1px solid #18ca8e;
  padding: 10px 0;
  border-radius: 10px;
  display: inline-block;
  text-align: center;
  background: linear-gradient(to right, #33cc81, #0b7eec, #20ca8a, #28cb86);
  background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.pagination-right-button {
  background-image: linear-gradient(to right,
      #1679ee,
      #0083e9,
      #008be2,
      #0092d8,
      #0098cd,
      #009fca,
      #00a6c5,
      #00acbe,
      #00b6b9,
      #00bfac,
      #00c798,
      #3acd7e);
  color: #fff !important;
  padding: 20px 0;
  border-radius: 10px;
  display: inline-block;
  text-align: center;
  /* width: 14%; */
}

#month option:first-child {
  display: none;
}

#year option:first-child {
  display: none;
}

.pagination-right-button img {
  height: 20px;
  height: 20px;
}

.pagination {
  margin: 0px 20px 0px 20px;
  justify-content: center;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-image: linear-gradient(to right,
      #1679ee,
      #0083e9,
      #008be2,
      #0092d8,
      #0098cd,
      #009fca,
      #00a6c5,
      #00acbe,
      #00b6b9,
      #00bfac,
      #00c798,
      #3acd7e);
  font-weight: 600;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #000;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #000;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

@media only screen and (max-width: 1399px) {}

@media only screen and (max-width: 1199px) {}

@media only screen and (max-width: 767px) {}

@media only screen and (max-width: 550px) {}

@media only screen and (max-width: 480px) {}